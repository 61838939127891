<template>
  <div class="view-post mb-20 md:mb-44 bg-amff-white text-amff-black-2024">
    <div class="relative my-10 md:my-0">
      <!-- DIV with title, date and share -->
      <div class="relative md:absolute left-0 right-0">
        <div
          class="grid grid-cols-1 md:grid-cols-2 max-w-container px-container"
        >
          <div class="flex flex-col justify-center">
            <div class="text-2xl md:text-4xl font-bold leading-tight">
              <div
                class="bg-amff-gray animate-pulse w-9/12 mb-1 md:mb2 rounded"
              >
                &nbsp;
              </div>
              <div
                class="
                  bg-amff-gray
                  animate-pulse
                  w-7/12
                  mb-1
                  md:mb2
                  rounded
                  hidden
                  md:block
                "
              >
                &nbsp;
              </div>
              <div
                class="bg-amff-gray animate-pulse w-8/12 mb-2 md:mb3 rounded"
              >
                &nbsp;
              </div>
            </div>
            <div
              class="
                text-sm
                sm:text-md
                md:text-lg
                text-amff-light-gray
                mb-2
                md:mb-4
              "
            >
              <div
                class="bg-amff-gray animate-pulse w-3/12 md:w-2/12 mb-2 rounded"
              >
                &nbsp;
              </div>
            </div>
            <div
              class="
                font-display
                text-md
                md:text-sm
                text-amff-teal
                cursor-pointer
                flex
                items-center
                mb-5
                md:mb-0
              "
            >
              <div class="bg-amff-gray animate-pulse w-4/12 md:w-3/12 rounded">
                &nbsp;
              </div>
            </div>
          </div>
          <div class="fill-post-image hidden md:block" />
        </div>
      </div>

      <!-- DIV with image -->
      <div class="grid grid-cols-1 md:grid-cols-2 md:absolute right-0 left-0">
        <div />
        <div class="relative fill-post-image bg-amff-gray animate-pulse">
          <!-- Image space -->
        </div>
      </div>
    </div>

    <!-- Div to fill margin  from top until content-->
    <div class="hidden md:grid grid-cols-2 mb-20">
      <div />
      <div class="relative fill-post-image" />
    </div>
    <!-- POST CONTENT -->
    <div class="px-container relative mx-auto max-w-3xl">
      <div class="bg-amff-gray animate-pulse w-1/4 mb-2 rounded">&nbsp;</div>
      <div
        class="bg-amff-gray animate-pulse w-4/4 mb-2 rounded"
        v-for="number in 20"
        :key="number"
      >
        &nbsp;
      </div>
    </div>
  </div>
</template>
