
import ShareButton from "@/components/ShareButton.vue";
import { defineComponent, ref, onMounted, computed } from "vue";
import usePosts from "@/composables/usePosts";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import { cdn } from "@/utils/cdn";
import { PostModel } from "@/domain/models/PostModel";
import PostSkeleton from "@/components/PostSkeleton.vue";
import ShareModal from "@/components/ShareModal.vue";
import { getPostJSONLD, withContext } from "@/domain/jsonld";

export default defineComponent({
  components: { PostSkeleton, ShareButton, ShareModal },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { currentLang } = useRouteWithLang();
    const { getPostBySlug } = usePosts(currentLang.value);

    const post = ref<PostModel | undefined>(undefined);
    const image = ref(null);
    const showImage = ref(false);
    const isShareModalOpen = ref(false);

    onMounted(async () => {
      post.value = await getPostBySlug(props.slug);
      if (post.value !== undefined) {
        image.value = cdn(post?.value.imagePost, "1200x696.67");
      }
    });

    const jsonld = computed(() =>
      !post.value
        ? undefined
        : JSON.stringify(
            withContext(
              getPostJSONLD({ post: post.value, lang: currentLang.value })
            )
          )
    );

    return { post, image, showImage, isShareModalOpen, jsonld };
  },
});
